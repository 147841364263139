module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-image@2.6.0_c357f1e2247cd18a13297bfe8b6fb0b7/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@4.6.0_gatsby@4.6.2/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"53aa06cf17e4239d0dba6ffd09854e02"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
